<template>
  <div class="header">
    <div class="header-left">
      <el-image :src="headerUrl" style="width: 80%;"></el-image>
    </div>
    <el-menu
      mode="horizontal"
      router
      text-color="#666666"
      active-text-color="#409EFF"
      :default-active="defaultActiveIndex"
      style="flex: 1; overflow-x: auto; overflow-y: hidden; height: 100%"
    >
      <el-menu-item
        v-for="(item, index) in titleListNew"
        mode="horizontal"
        :key="item.path"
        :index="item.path"
      >
        <span slot="title"
          >{{ item.name
          }}<i
            class="el-icon-circle-close del"
            v-show="item.path != '/home/homepage'"
            @click.stop="handleDelete(index)"
          ></i
        ></span>
      </el-menu-item>
    </el-menu>
    <div class="cp_right">
      <img class="headerimg" src="@/assets/imgs/userimg.png" />
      <el-dropdown @command="handleCommand">
        <span class="">
          {{ usergt.username }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!--<el-dropdown-item command="a">修改密码</el-dropdown-item>-->
          <el-dropdown-item command="b">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="false"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        ref="formRef"
        :rules="rules"
        size="small"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="旧密码" prop="oldPwd">
          <el-input v-model="form.oldPwd"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd">
          <el-input v-model="form.newPwd"></el-input>
        </el-form-item>
        <el-form-item label="再次输入" prop="repeat">
          <el-input v-model="form.repeat"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          class="confirm-button"
          type="primary"
          @click="handleConfirm"
          >确 定</el-button
        >
        <el-button size="small" class="cancel-button" @click="handleClose"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      defaultActiveIndex: "",
      dialogVisible: false,
      form: {
        oldPwd: "",
        newPwd: "",
        repeat: "",
      },
      headerUrl: require("@/assets/imgs/pic_logo.png"),
      rules: {
        oldPwd: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        newPwd: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
            message: "密码至少8位，包含大小写字母和数字",
            trigger: "blur",
          },
        ],
        repeat: [
          { required: true, message: "请确认新密码", trigger: "blur" },
          { validator: this.validatePassword, trigger: "blur" },
        ],
      },
    };
  },
  components: {
  },
  computed: {
    ...mapGetters(["usergt"]),
    ...mapState(["titleListNew"]),
    isCollapse() {
      return store.state.isCollapse;
    },
  },
  watch: {
    $route: {
      handler(newValue, oldValue) {
        this.defaultActiveIndex = newValue.fullPath;
      },
      immediate: true,
    },
  },
  methods: {
    changeCollapse() {
      store.state.isCollapse = !store.state.isCollapse;
    },
    handleDelete(index) {
      if (this.titleListNew[index].path == this.$route.path) {
        if (this.titleListNew.length == 1) {
          this.titleListNew.splice(index, 1);
          this.$router.push({ path: "/home/homepage" });
          this.titleListNew.push({
            id: 101,
            name: "首页",
            path: "/home/homepage",
            icon: "icon-shouye",
          });
        } else {
          this.$router.push({ path: this.titleListNew[index - 1].path });
          this.titleListNew.splice(index, 1);
        }
      } else {
        this.titleListNew.splice(index, 1);
      }
      window.sessionStorage.setItem(
        "titleListNew",
        JSON.stringify(this.titleListNew)
      );
    },
    handleCommand(command) {
      if (command == "a") {
        this.dialogVisible = true;
      } else if (command == "b") {
        this.logout();
      }
    },
    handleClose() {
      this.$refs.formRef.resetFields();
      this.dialogVisible = false;
    },
    handleConfirm() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          const res = await this.$http("api1", "/sys/user/updatePwd", "post", {
            oldPwd: this.form.oldPwd,
            newPwd: this.form.newPwd,
          });
          if (res.data.code == 0) {
            this.$message.success("密码修改成功");
            this.$refs.formRef.resetFields();
            this.dialogVisible = false;
            this.logout();
          }
        } else {
        }
      });
    },
    validatePassword(rule, value, callback) {
      if (value !== this.form.newPwd) {
        callback(new Error("两次输入的新密码不一致"));
      } else {
        callback();
      }
    },
    async logout() {
      const { data } = await this.$http("api1", "/sys/logout", "post");
      if (data.code == 0) this.$message.success("退出登录成功");
      this.titleListNew = [];
      window.sessionStorage.removeItem("titleListNew");
      window.sessionStorage.removeItem("itemindex");
      window.sessionStorage.removeItem("routeindex");
      window.sessionStorage.removeItem("user");
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0.1em;
  height: 100%;
}
.header-left {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 200px;
  background-color: #409eff;
  color: #fff;
  font-size: 20px;
  background: linear-gradient(270deg, #B0C4DE 0%, #4682B4 100%);
}

.del {
  position: absolute;
  right: -5px;
  top: 5px;
  font-size: 16px;
  visibility: hidden;
}
.el-menu-item:hover .del {
  visibility: visible;
}
.cp_left > *:not(:first-child) {
  margin: 0 10px;
}
.headerimg {
  width: 40px;
  height: 40px;
}
.cp_right {
  display: flex;
  align-items: center;
}

.cp_right > * {
  margin: 0 10px;
}
</style>
