<template>
  <div class="maside">
    <template v-for="item in arrlist">
      <el-submenu v-if="item.children" :key="item.id" :index="item.id + ''">
        <template slot="title">
          <i :class="item.icon"></i>
          <span>{{ item.name }}</span>
          <div class="badge" v-if="item.name == '审核管理'">
            {{ getCount(item) }}
          </div>
        </template>
        <m-aside :arrlist="item.children || {}"></m-aside>
      </el-submenu>
      <el-menu-item
        v-else
        :key="item.path"
        :index="item.path"
        @click="clickMenuItem(item)"
        ><i class="el-icon-s-home" v-if="item.name == '首页'"></i>

        <span slot="title" style="padding-left: 10px">
          <span>{{ item.name }}</span>
          <div
            class="badge"
            v-if="
              pendingCount.map((x) => x.index).includes(item.path) &&
              pendingCount.find((x) => x.index == item.path)?.count > 0
            "
          >
            {{ pendingCount.find((x) => x.index == item.path)?.count }}
          </div></span
        >
      </el-menu-item>
    </template>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { mapState } from "vuex";
export default {
  name: "mAside",
  props: ["arrlist"],
  computed: {
    ...mapState(["pendingCount"]),
  },
  methods: {
    ...mapMutations(["addTitleList"]),
    clickMenuItem(item) {
      this.addTitleList(item);
    },
    getCount(item) {
      let children = item.children.map((x) => x.path);
      console.log(children);
      return this.sum(
        this.pendingCount
          .filter((x) => children.includes(x.index))
          .map((x) => x.count)
      );
    },
    sum(numbers) {
      return numbers.reduce(
        (accumulator, currentValue) => accumulator + currentValue
      );
    },
  },
};
</script>
<style scoped>
.msg {
  margin: 0 5%;
}
.info {
  color: #fff;
  background: #ff5926;
  border-radius: 5px;
  font-size: 50%;
  padding: 0 2px;
}
.badge {
  background-color: #f56c6c;
  border-radius: 10px;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  height: 18px;
  line-height: 18px;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid transparent;
  margin-left: 5px;
}
</style>
