<template>
  <el-container style="height: 100%; width: 100%; overflow: hidden">
    <el-header style="padding: 0">
      <p-header></p-header>
    </el-header>
    <el-container style="height: calc(100% - 60px)">
      <el-aside width="200px">
        <el-menu
          router
          unique-opened
          :collapse="isCollapse"
          background-color="#343941"
          text-color="#A9ABAD"
          active-text-color="#fff"
          :default-active="defaultActiveIndex"
          style="height: 100%"
        >
          <m-aside :arrlist="routerlist"></m-aside>
        </el-menu>
      </el-aside>
      <el-main style="padding: 10px; background-color: #f0f0f0">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import pHeader from "@/components/pHeader.vue";
import mAside from "@/components/mAside.vue";
import store from "../store";
import { mapState } from "vuex";
export default {
  provide() {
    return {
      reload: this.reload,
      changerefresh: this.changerefresh,
      alertDialog: this.alertDialog,
    };
  },
  data() {
    return {
      refresh: false,
      defaultActiveIndex: "",
      pendingRevie: {},
      routerlist: [
        {
          id: 101,
          name: "首页",
          path: "/home/homepage",
          icon: "icon-shouye",
        },
      ],
    };
  },
  computed: {
    isCollapse() {
      return store.state.isCollapse;
    },
    ...mapState(["pendingCount"]),
  },
  watch: {
    $route: {
      handler(newValue, oldValue) {
        this.defaultActiveIndex = newValue.fullPath;
      },
      immediate: true,
    },
  },
  components: {
    pHeader,
    mAside,
  },
  created() {
    this.getmenu();
    this.getPendingCount();
    setInterval(() => {
      this.getPendingCount();
    }, 5000);
  },
  methods: {
    async getmenu() {
      const { data: data1 } = await this.$http("api1", "/sys/menu/nav", "get");
      if (!data1 || data1.msg != "success") return this.$router.push("/login");
      data1.menuList.forEach((item) => {
        var ch = [];
        item.list.forEach((it) => {
          ch.push({
            id: it.menuId,
            name: it.name,
            path: it.url,
          });
        });
        this.routerlist.push({
          id: item.menuId,
          name: item.name,
          icon: item.icon,
          children: ch,
        });
      });
    },
    async getPendingCount() {
      const res = await this.$http("api1", "/api/stat/pendingNum", "get");
      for (let item of this.pendingCount) {
        item.count = res.data[item.key];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main_container_box {
  height: 100%;
}
</style>
